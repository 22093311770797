//
// STORE R.JS

/*
  Pendiente: Analizar meter cabeceras del finder para actualizar los campos del Grid en la función Guardar
*/

import Vue from "vue";

const getDefaultState = () => {
  return {
    storeTipo:'R',
    records:[],
    recordsRead:[],
    recordsSelected:[],
    //recordsAdjuntos: [],
    perm: [],
    permExtra: [],
    //selection:'',  // first, preserve, both

    //ID:0 (solo storeM)
    accion:'',
    modal:false,
    accionCt:{},
    disparo:false,
    //estado: "indefinido", (solo storeM)
  };
};


export default {
  namespaced: true,
  state: () => {
    return {
      api: "",
      name:"",
      titulo: "",
      // relation: "", (solo store M)
      mView:"",
      pView:[],
      //expansibleMX: "",

      sch: 0,
      getRecordsArray:0,

      ...getDefaultState()
    };
  },


  mutations: {
    // inicializar variables del state
    INI(state, data) {
      Object.assign(state, data);
      console.log("INI store", data);
    },

    // resetear variables del state
    RESET(state) {
      Object.assign(state, getDefaultState());
      console.log("RESET store");
    },

    // guardar schema, permisos y botonera en el store
    SCHSET(state, data) {
      Vue.set(state, "sch", data[0]);
    },

    //
    PESOSET(state, data) {
      Vue.set(state, "perm", data.permisoMto);
      Vue.set(state, "permExtra", data.permisoAccion);
    },

    // actualizar propiedad del state
    data2State(state, data) {
      if (data.key) {
        Vue.set(state[data.prop], data.key, data.value);
        return;
      }

      Vue.set(state, data.prop, data.value);
    },

    recordReadSetOnly(state, records) {
      if (!records) return;

      Vue.set(state, "records", JSON.parse(JSON.stringify(records[0])));
      Vue.set(state, "recordsRead", JSON.parse(JSON.stringify(records)));
    },

    recordReadSet(state, records) {
      if (!records) return;

      Vue.set(state, "records", JSON.parse(JSON.stringify(records[1])));
      Vue.set(state, "recordsRead", JSON.parse(JSON.stringify(records)));
      Vue.set(state, "recordsSelected", []);
    },

    recordSetOnly(state, records) {
      Vue.set(state, "records", JSON.parse(JSON.stringify(records)));
    },

    recordSet(state, records) {
      Vue.set(state, "records", JSON.parse(JSON.stringify(records)));
      Vue.set(state, "recordsSelected", []);
    },

    //
    recordsSelectedSet(state, records) {
      Vue.set(state, "recordsSelected", records);
    },

    // records adjuntos al record principal, generalmente informacion (tipos, recuentos, ...)
    /* recordsAdjuntos(state, records) {
      Vue.set(state, "recordsAdjuntos", records)
    }, */

    // actualizo variables de acciones Grid
    accionSet(state, { accion}) {
      console.log('accionSet;; ', accion);
      Vue.set(state, "accion", accion);
    },

    // actualizo variables de acciones Grid
    accionCtSet(state, accionCt) {
      console.log('accionCtSet;; ', accionCt);
      Vue.set(state, "accionCt", accionCt);
    },

    //
    disparoSet(state) {
      state.disparo= !state.disparo;
    },

    modalSet(state, val) {
      Vue.set(state, "modal", val);
    },


    // TEMPORALMENTE CREADO PARA EVITAR UN ERROR AL HACER UNA OPERACIÓN
    // EN UN MTO (ANTIGUA VERSIÓN) CON UN MAESTRO DE LA NUEVA VERSIÓN.
    update_record() {}


  },


  actions: {
    // cargar schema Mto
    async ini({dispatch, commit, rootGetters}, { stIni, schAPI, storeName }) {
      commit("INI", stIni);

      // guardo permisos de mView
      let peso= rootGetters.pesoGet(stIni.mView || stIni.name);
      commit("PESOSET", peso);

      // si sch es null no se ejecuta la acción schGet
      // se supone que se sobreescribe el computed sch del particular
      // y se devuelve a pelo el sch
      // Pendiente: mirar lo que se devuelve
      if (!schAPI) return;

      await dispatch('schGet', { storeName:storeName, api:stIni.api, schAPI:schAPI }, {root:true});
    },


    // recargo Finder
    refresh(context) {
      context.commit('disparoSet');
    },


    // obtengo registros del Finder
    async recordsGet(context, { param, masterStore })
    {
      console.log("DEV " + context.state.api + ": recordsGet", param, 'masterStore ', masterStore);

      // call API
      let apiResult= await context.dispatch('apiCall', param, {root:true});
      console.log("result recordGet: ", apiResult);

      // blanqueo records si hay algún error en la búsqueda
      if (apiResult.sql.error) {
        context.commit('recordSet', []);
        return;
      }

      // actualiza schema
      await context.commit('SCHSET', apiResult.r);

      // actualiza variables del state
      await context.commit('recordReadSet', apiResult.r);
    },


    // ordena registros por nombre de campo y tipo de ordenación
    // y asigna esos records al GRID
    ordenarRecords(context, { campo, orden, fn }) {
      let records = JSON.parse(JSON.stringify(context.state.records));

      //
      if (!fn) {
        records.sort((a, b)=> {
          if (orden== 'ASC') {
            return a[campo].trim().localeCompare (b[campo].trim())

          }else {
            return b[campo].trim().localeCompare (a[campo].trim())
          }
        });

      }else {
        records= fn(records);
      }

      context.dispatch('recordSetPreserve', records);
    },


    // ejecuta fn particular para filtrar los records
    // y asigna esos records al GRID
    async filtrarRecords(context, { fn, params }) {
      let records= await fn(JSON.parse(JSON.stringify(context.state.recordsRead[0])), params);
      context.dispatch('recordSetPreserve', records);
    }


  },


  getters: {
    // array de records del Grid
    /* recordsGrid: state => {
      return state.records;
    }, */


    // creo syncRecord para polimorfismo entre stores sobre el record activo o seleccionado
    // en storeF tenemos el seleccionado en recordsSelected[0]
    // en storeM tenemos el activo en record
    // en storeMD tenemos el seleccionado en recordsSelected[0]
    // ...
    // normalizamos con el getter en synRecord
    /* syncRecord: state => {
      if (!state.recordsSelected.length) return {};
      if (state.recordsSelected.length== 1) return state.recordsSelected[0];
      return state.recordsSelected;
    }, */


    // records adjuntos al record principal, generalmente informacion (tipos, recuentos, ...)
    /* recordsAdjuntos: state=> (indice) => {
      if (typeof state.recordsAdjuntos[indice]=== 'undefined') return [];
      return state.recordsAdjuntos[indice];
    }, */

  }
};
